.sentiment {
  border-radius: 15px;
  color: var(--color-primary) !important;
  padding: 5px 15px;
  text-transform: capitalize;
  width: fit-content;
}

.chartRow {
  height: 2.5rem;
  width: 100%;
  display: flex;
  transition: all .3s ease-in-out;
  border-radius: 0.5rem;
  overflow: hidden;
}

.chartItem {
  height: 100%;
  width: 0;
  transition: all .3s ease-in-out;
}

.legendRow {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.legendItem {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.legendColor {
  width: 0.75rem;
  aspect-ratio: 1;
  border-radius: 50%;
}


@font-family: 'DM Sans', sans-serif;@border-radius-base: 0.5rem;