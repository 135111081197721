.tag {
  border-radius: 0.25rem;
  width: 6.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  padding: 0.5rem 1rem;

  &.positive {
    background: var(--color-positive-background);
    color: var(--color-positive-text);
  }

  &.neutral {
    background: var(--color-neutral-background);
    color: var(--color-neutral-text);
  }

  &.negative {
    background: var(--color-negative-background);
    color: var(--color-negative-text);
  }

  &.draft {
    background-color: var(--color-gray);
  }
}

@font-family: 'DM Sans', sans-serif;@border-radius-base: 0.5rem;