.layout {
  height: 100vh;
  background: var(--color-page-background) !important;
}


.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  position: relative;
}

@font-family: 'DM Sans', sans-serif;@border-radius-base: 0.5rem;