@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap');
@import '~antd/dist/antd.less';

[data-theme='light'] {
  --color-white: #F0F0F0;
  --color-black: #1E1E1E;

  --color-page-background: #E6E7EB;
  --color-card-background: rgba(255, 255, 255, .5);
  --color-card-background-inactive: rgba(223, 221, 221, .5);
  --color-audio-background: #DFDDDD;

  --color-primary: #1E1E1E;
  --color-secondary: #F0F0F0;

  --color-stroke: #6B7280;

  --color-neutral-background: #DBEAFE;
  --color-neutral-text: #1E40AF;

  --color-positive-background: #DCFCE7;
  --color-positive-text: #166534;

  --color-negative-background: #FEE2E2;
  --color-negative-text: #991B1B;

  --color-table-row: #EFEFEF;


  --color-brand-blue: #19ACEF;
  --color-brand-blue-15: rgba(25, 172, 239, 0.15);
  --color-brand-green: #27F06A;
  --color-brand-red: #F42D5F;
  --color-brand-orange: #f0ad4e;
}

[data-theme='dark'] {
  --color-white: #F0F0F0;
  --color-black: #1E1E1E;

  --color-page-background: #1B263B;
  --color-card-background: #2C3E5080;
  --color-card-background-inactive: rgba(223, 221, 221, .5);
  --color-audio-background: #DFDDDD;

  --color-primary: #F0F0F0;
  --color-secondary: #1E1E1E;

  --color-stroke: #6B7280;

  --color-neutral-background: #1E40AF;
  --color-neutral-text: #DBEAFE;

  --color-positive-background: #166534;
  --color-positive-text: #DBEAFE;

  --color-negative-background: #991B1B;
  --color-negative-text: #DBEAFE;

  --color-table-row: #3A4B6666;


  --color-brand-blue: #19ACEF;
  --color-brand-blue-15: rgba(25, 172, 239, 0.15);
  --color-brand-green: #27F06A;
  --color-brand-red: #F42D5F;
  --color-brand-orange: #f0ad4e;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: DM Sans, sans-serif !important;
  letter-spacing: 0;

  &:not(textarea)::-webkit-scrollbar {
    width: 0;

    &-track {
      background-color: transparent;
    }

    &-thumb {
      background-color: var(--color-card-background);
      border-radius: 0.25rem;
      cursor: pointer;

      &:hover {
        background-color: var(--color-card-background);
      }
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
  -webkit-background-clip: text;
  -webkit-text-fill-color: var(--color-primary);
  background: transparent;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: none;
  caret-color: var(--color-primary);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}


h1 {
  margin: 0;
}

body {
  min-height: 100vh;
}

a {
  color: var(--color-primary);
  cursor: pointer;
  position: relative;
  text-decoration: none;
  font-size: 1rem;
  padding-bottom: 2px;
  text-align: center;

  &::before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: var(--color-primary);
    transform: scaleX(0);
    transition: transform 0.3s ease;
  }

  &:hover {
    color: inherit;
  }

  &:hover::before {
    transform: scaleX(1);
  }
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.color {
  &-inherit {
    color: inherit !important;
  }

  &-white {
    color: var(--color-white) !important;
  }

  &-primary {
    color: var(--color-primary) !important;
  }

  &-stroke {
    color: var(--color-stroke) !important;
  }

  &-green {
    color: var(--color-brand-green) !important;
  }

  &-blue {
    color: var(--color-brand-blue) !important;
  }

  &-red {
    color: var(--color-brand-red) !important;
  }
}

.border {
  &-1 {
    border: 1px solid;
  }

  &-color {
    &-primary {
      border-color: var(--color-primary) !important;
    }
  }
}

.m {
  &-auto {
    margin: auto !important;
  }

  &-0 {
    margin: 0 !important;
  }

  &t {
    &-8 {
      margin-top: 0.5rem;
    }

    &-10 {
      margin-top: 10px;
    }
  }

  &b {
    &-4 {
      margin-bottom: 0.25rem;
    }
  }
}

.p {
  &-0 {
    padding: 0 !important;
  }
  &v {
    &-4 {
      padding-block: 0.25rem;
    }
    &-8 {
      padding-block: 0.5rem;
    }
    &-40 {
      padding-block: 2.5rem;
    }
  }
  &h {
    &-20 {
      padding-inline: 1.25rem;
    }
    &-40 {
      padding-inline: 2.5rem;
    }
  }
  &r {
    &-40 {
      padding-right: 2.5rem;
    }
  }
}

.fs {
  &-12 {
    font-size: 0.75rem !important;
    line-height: 1;
  }
  &-14 {
    font-size: 0.875rem !important;
    line-height: 1;
  }
  &-16 {
    font-size: 1rem !important;
    line-height: 1;
  }
  &-18 {
    font-size: 1.125rem !important;
    line-height: 1.5;
  }
  &-20 {
    font-size: 1.25rem !important;
    line-height: 1;
  }
  &-24 {
    font-size: 1.5rem !important;
    line-height: 1;
  }
  &-26 {
    font-size: 1.625rem !important;
    line-height: 1;
  }
  &-32 {
    font-size: 2rem !important;
    line-height: 1;
  }
  &-36 {
    font-size: 2.25rem !important;
    line-height: 1.2;
  }
  &-40 {
    font-size: 2.5rem !important;
    line-height: 1;
  }
  &-48 {
    font-size: 4.8rem;
  }
  &-80 {
    font-size: 5rem !important;
    line-height: 1;
  }
}

.fw {
  &-400 {
    font-weight: 400;
  }
  &-500 {
    font-weight: 500;
  }
  &-700 {
    font-weight: 700;
  }
}

.lh {
  &-1 {
    line-height: 1;
  }
  &-16 {
    line-height: 1rem;
  }
  &-20 {
    line-height: 1.25rem;
  }
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.min {
  &-h {
    &-200 {
      min-height: 200px;
    }
  }
}

.ws {
  &-nowrap {
    white-space: nowrap;
  }
}

.gap {
  &-4 {
    gap: 0.25rem;
  }
  &-8 {
    gap: 0.5rem;
  }
  &-12 {
    gap: 0.75rem;
  }
  &-16 {
    gap: 1rem;
  }
  &-24 {
    gap: 1.5rem;
  }
  &-40 {
    gap: 2.5rem;
  }
}

.align {
  &-left {
    text-align: left;
  }

  &-center {
    text-align: center;
  }

  &-right {
    text-align: right;
  }
}

.flex {
  &-1 {
    flex: 1 1 auto;
  }

  &-wrap {
    flex-wrap: wrap;
  }

  &-row {
    display: flex;
    flex-direction: row;
  }

  &-column {
    display: flex;
    flex-direction: column;
  }

  &-align {
    &-start {
      align-items: flex-start;
    }
    &-center {
      align-items: center;
    }
    &-end {
      align-items: flex-end;
    }
  }

  &-justify {
    &-start {
      justify-content: flex-start;
    }
    &-center {
      justify-content: center;
    }
    &-end {
      justify-content: flex-end;
    }
    &-space-between {
      justify-content: space-between;
    }
  }
}

.grid {
  display: grid;

  &-cols {
    &-2 {
      grid-template-columns: repeat(2, 1fr);
    }

    &-3 {
      grid-template-columns: 1fr 1fr 1fr;
    }

    &-autofill {
      grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    }


    &-300-auto {
      grid-template-columns: 300px auto;
    }
  }
}

.text-decoration {
  &-none {
    text-decoration: none;
  }
}

.transition {
  &-all {
    transition: all .3s
  }
}

.rotate {
  &-180 {
    rotate: 180deg
  }
}

.background {
  &-transparent {
    background: transparent;
  }
}

.backdrop-filter {
  &-blur {
    &-10 {
      backdrop-filter: blur(10px);
    }
  }
}

.border-radius {
  &-8 {
    border-radius: 8px;
  }

  &-16 {
    border-radius: 16px;
  }
}

.btn-danger {
  border-radius: 4px !important;
  border: 0;
  color: var(--color-primary);

  &:hover {
    color: var(--color-primary);
    background: var(--color-brand-red);
  }
}

.z-index {
  &-50 {
    z-index: 100;
  }
  &-100 {
    z-index: 100;
  }
}

.icon-button {
  padding: 0;
  width: 30px !important;
  height: 30px !important;
  align-items: center;
  justify-content: center;
}

.ant {
  &-typography {
    color: var(--color-primary);

    a {
      font-size: 1em !important;
      color: var(--color-brand-blue) !important;

      &::before {
        background-color: var(--color-brand-blue);
      }
    }
  }

  &-form {
    &-item {
      width: 100%;
      margin-bottom: 1.25rem;

      &-required {
        &:before {
          display: none !important;
        }
      }

      &-explain-error {
        color: var(--color-brand-red);
      }

      &-label {
          &>label {
            color: var(--color-primary) !important;
            font-size: 1rem;
            font-weight: 600;
            line-height: 1.2;

            &:before {
              content: none !important;
            }
          }
      }
    }
  }

  &-pro {
    &-table {
      width: 100%;
    }

    &-card {
      background: transparent;
    }
  }

  &-table {
    background: transparent;

    table {
      border-collapse: separate;
      border-spacing: 0;
    }

    &-column-sort {
      background: transparent !important;
    }

    &-thead th.ant-table-column-sort {
      background: transparent !important;
    }

    &-cell {
      border: 0 !important;

      a {
        font-size: 1em !important;
        color: var(--color-primary) !important;

        &::before {
          background-color: var(--color-brand-blue);
        }
      }
    }

    &-thead {
      .ant-table-cell {
        background: transparent;
        color: var(--color-primary);
        font-size: 0.75rem;
        line-height: 1.2;
        font-weight: 400;
        border-bottom: 1px solid var(--color-stroke) !important;

        &:before {
          display: none;
        }
      }
    }

    &-row {
      .ant-table-cell {
        padding: 0.75rem 1rem !important;
      }

      &:nth-child(even) {
        .ant-table-cell {
          background: var(--color-table-row);
        }
      }

      &:hover {
        .ant-table-cell {
          background: var(--color-card-background) !important;
        }
      }
    }

    &-placeholder {
      &:hover {
        .ant-table-cell {
          background: transparent !important;
        }
      }
    }
  }

  &-input-affix-wrapper-focused {
    border-color: var(--color-brand-blue) !important;
    box-shadow: 0 0 2px 0 var(--color-brand-blue) !important;
  }

  &-btn {
    border-radius: 0.25rem;
    font-size: 1rem;
    font-weight: 500;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: 2.5rem;
    box-shadow: none;
    transition: .3s all ease-in-out;

    &-lg {
      height: 3rem;
    }

    &-default {
      background: transparent;
      color: var(--color-primary);
      border: 1px solid var(--color-primary);
      padding-inline: 2.5rem;

      &:focus {
        color: var(--color-primary);
      }

      &:hover {
        background: transparent;
        color: var(--color-brand-blue);
        border: 1px solid var(--color-primary);
        outline: none;
      }
    }

    &-primary {
      background: var(--color-primary);
      color: var(--color-secondary);
      border: 1px solid var(--color-primary);
      padding-inline: 2.5rem;

      &:focus {
        background: var(--color-primary);
        color: var(--color-secondary);
        border: 1px solid var(--color-primary);
      }

      &:hover {
        background: var(--color-primary);
        color: var(--color-secondary);
        border: 1px solid var(--color-primary);
        opacity: .8;
      }
    }

    &.ant-btn-dangerous {
      color: var(--color-white);
      border: 1px solid var(--color-brand-red);
      background: var(--color-brand-red);


      &:hover {
        color: var(--color-white);
        background: var(--color-brand-red);
        border: 1px solid var(--color-white);
      }

      &:focus {
        color: var(--color-white);
        border: 1px solid var(--color-brand-red);
        background: var(--color-brand-red);
      }
    }
  }

  &-select {
    &-selector {
      height: 100% !important;
      background: transparent !important;
      border: 0 !important;
    }

    &-selection {
      &-search,
      &-item {
        display: flex;
        align-items: center;
      }
    }

    &-arrow {
      color: var(--color-primary);
    }

    &-clear {
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-dropdown {
      background: var(--color-page-background);
    }

    &-item-option {
      .ant-btn {
        width: 2.5rem;
        height: 2.5rem;
      }

      &-active {
        background: var(--color-table-row) !important;

        .ant-btn {
          color: var(--color-brand-blue) !important;
        }
      }

      &-selected {
        background: var(--color-brand-blue-15) !important;
      }
    }
  }

  &-select,
  &-input,
  &-input-password,
  &-input-affix-wrapper {
    border: 1px solid var(--color-primary);
    border-radius: 0.5rem;
    color: var(--color-primary);
    background: transparent !important;

    &:hover {
      border-color: var(--color-brand-blue) !important;
    }

    &:focus {
      border-color: var(--color-stroke) !important;
      box-shadow: 0 0 2px 0 var(--color-stroke) !important;
    }

    &-lg,
    &-large {
      height: 50px;
    }

    &-icon {
      color: var(--color-primary) !important;
    }

    &-affix-wrapper-status-error,
    &-status-error {
      background: transparent !important;
      border: 1px solid var(--color-brand-red) !important;
    }

    .ant-input {
      background: transparent;

      &-lg {
        height: auto;
      }
    }
  }

  &-result {
    &-title,
    &-subtitle {
      color: var(--color-primary);
    }
  }

  &-card {
    &-body {
      padding: 0 !important;
      height: 100%;
    }
  }

  &-checkbox,
  &-checkbox-checked {
    &:hover {
      .ant-checkbox-inner {
        border-color: var(--color-brand-blue) !important;
      }
    }
  }

  &-segmented {
    &-group {
      background: var(--color-page-background);
      padding: 0.5rem 0.25rem;
    }

    &-item {
      background: transparent;
      color: var(--color-primary);
      border-radius: 0.5rem;

      &:hover {
        color: var(--color-brand-blue)
      }

      &-selected {
        background: var(--color-card-background);

        &:hover {
          color: var(--color-primary)
        }
      }
    }

    &-thumb {
      background: var(--color-card-background);
      height: calc(100% - 1rem);
      top: 0.5rem;
    }
  }

  &-switch {
    background: var(--color-secondary) !important;
    border: 1px solid var(--color-primary) !important;

    &:focus {
      box-shadow: none !important;
    }

    .ant-switch-handle {
      top: 1px;

      &:before {
        background: var(--color-stroke);
      }
    }

    &-checked {
      .ant-switch-handle {
        &:before {
          background: var(--color-brand-blue);
        }
      }
    }
  }

  &-tabs {
    color: var(--color-primary);

    &-tab {
      padding: 0.25rem 0.5rem !important;
      background: transparent !important;
      border: 0 !important;
      border-bottom: 2px solid transparent !important;
      border-radius: 5px 5px 0 0 !important;

      &-active {
        border-bottom: 2px solid var(--color-primary) !important;

        .ant-tabs-tab-btn {
          color: var(--color-primary) !important;
        }
      }
    }

    &-tabpane {
      height: 100%;
    }

    &-nav {
      margin: 0 !important;

      &:before {
        display: none;
      }
    }

    &-content {
      height: 100%;

      &-holder {
        padding: 1rem 0;
      }
    }
  }

  &-modal {
    min-width: 30rem;
    padding: 0;
    width: fit-content !important;

    &-content {
      background: var(--color-card-background) !important;
      backdrop-filter: blur(5px);
    }

    &-header {
      padding: 2.5rem 1.25rem 1.25rem;
      background: transparent !important;
      border: 0;
    }

    &-close-x {
      font-size: 1.5rem;
      color: var(--color-primary);
    }

    &-title {
      font-size: 2.25rem;
      line-height: 1.2;
      color: var(--color-primary);
      padding-right: 2.5rem;
      white-space: nowrap;
    }

    &-body {
      background: transparent !important;
      padding: 0;
    }

    &-footer {
      display: flex;
      justify-content: flex-end;
    }

    &-mask {
      background-color: transparent !important;
      backdrop-filter: blur(10px);
    }

    &-confirm {
      &-body {
        .ant-modal-confirm-title {
          color: var(--color-primary);
        }

        .ant-modal-confirm-content {
          color: var(--color-primary);
        }
      }
    }
  }

  &-notification {
    &-notice {
      &-icon {
        color: var(--color-black);
        font-size: 24px;
      }

      &-message {
        color: var(--color-black);
        margin-bottom: 0 !important;
        line-height: 1.5rem;
      }

      &-info {
        background: var(--color-brand-blue);
      }

      &-error {
        background: var(--color-brand-red);
      }

      &-success {
        background: var(--color-brand-green);
      }

      &-warning {
        background: var(--color-brand-orange);
      }

      &-close {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 1.5rem;
        aspect-ratio: 1;
        color: var(--color-black);
        text-decoration: none;
        font-size: 1rem;

        &:hover {
          color: var(--color-brand-blue);
        }

        &:before {
          display: none;
        }
      }
    }
  }

  &-pagination {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    &-jump-prev,
    &-jump-next,
    &-item {
      width: 2.5rem;
      height: 2.5rem;
      background: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;

      .ant-btn-text {
        padding: 0;
        width: 100%;
        color: var(--color-primary);

        .ant-pagination-item-container {
          display: flex;
          justify-content: center;
        }

        a {
          &:before {
            display: none;
          }
        }
      }

      &-active {
        border-color: var(--color-brand-blue);

        .ant-btn-text {
          color: var(--color-brand-blue);
        }
      }

      &:hover {
        border-color: var(--color-brand-blue);

        .ant-btn-text {
          background-size: 100% 2px;
        }
      }

      .ant-pagination-item-link {
        .ant-pagination-item-container {
          display: flex;
          align-items: center;

          .ant-pagination-item-ellipsis {
            top: 50%;
            left: 50%;
            right: auto;
            bottom: auto;
            transform: translate(-50%, -50%);
            color: var(--color-primary);
            font-size: 1rem;
          }
        }
      }
    }

    &-prev,
    &-next {
      transition: none;

      .ant-btn-text {
        color: var(--color-primary);
        border: 0;
        text-transform: capitalize;

        &:hover {
          border: 0;
        }
      }
    }

    &-disabled {
      opacity: 0;
      pointer-events: none;
      visibility: hidden;
    }
  }

  &-empty {
    &-description {
      color: var(--color-primary);
    }
  }

  &-upload {
    padding: 1.25rem;
    border-radius: 50% !important;
    margin: 0 !important;
    width: 7rem !important;
    height: 7rem !important;

    &.ant-upload-select {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-list {
      display: flex !important;
      justify-content: center !important;
      min-height: 7rem !important;

      &-picture-card-container {
        margin: 0 !important;
        width: 7rem !important;
        height: 7rem !important;
      }

      &-item {
        border-radius: 50% !important;
        border-color: var(--color-brand-blue) !important;

        &-info {
          border-radius: 50% !important;
        }
      }
    }
  }

  &-dropdown {
    &-menu {
      &-root {
        background: transparent;
      }

      &-item {
        &:hover {
          background: transparent;
        }
      }
    }
  }
}

.ant-modal-confirm-btns {
  display: flex;
  flex-direction: row;
  gap: 1.25rem;
  justify-content: space-between;
}

.table-without-header {
  .ant-table-thead {
    display: none !important;
  }
}

.activation-id-button {
  .ant-input {
    color: var(--color-primary);
  }

  &:hover {
    border: 1px solid var(--color-primary) !important;
  }
}

@keyframes jump {
  0% {
    transform: translateY(0) rotateY(360deg)
  }
  25% {
    transform: translateY(-50px) rotateY(360deg)
  }
  50% {
    transform: translateY(0) rotateY(360deg)
  }
  60% {
    transform: translateY(-25px) rotateY(360deg)
  }
  65% {
    transform: translateY(-50px) rotateY(180deg)
  }
  70% {
    transform: translateY(-25px) rotateY(360deg)
  }
  80% {
    transform: translateY(0) rotateY(360deg)
  }
}


@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(0.5rem);
  }
  75% {
    transform: translateX(-0.5rem);
  }
  100% {
    transform: translateX(0);
  }
}

@font-family: 'DM Sans', sans-serif;@border-radius-base: 0.5rem;