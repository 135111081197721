.cardsWrapper {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  margin: auto;
}

.cardContainer {
  padding: 3rem 2rem 2.5rem;
  background: var(--color-card-background);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: hidden;
  flex: 0 0 max(25rem, 35%);
}

.cardActive {
  border: 1px solid var(--color-brand-blue);
}

.icon {
  width: 2rem;
  height: 2rem;
  flex: 0 0 2rem;
  border-radius: 50%;
  background: var(--color-primary);
  color: var(--color-secondary);
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    font-size: 1rem;
  }
}

.buyButton {
  margin-top: auto;
  align-self: center;
}

@font-family: 'DM Sans', sans-serif;@border-radius-base: 0.5rem;