.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.25rem;
  width: 450px;
  max-width: 100%;
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
  transition: .3s all ease-in-out;

  .logo {
    font-size: 2rem;
    color: var(--color-primary)
  }

  .iconContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;

    .icon {
      font-size: 2.75rem;
      color: var(--color-primary);
    }
  }

  .title {
    color: var(--color-primary);
    font-size: 1.625rem;
    font-weight: 600;
    line-height: 2rem;
    text-align: center;
    margin: 0;
  }

  .description {
    display: block;
    color: var(--color-primary);
    font-size: 1rem;
    font-weight: 400;
    padding-inline: 2.25rem;
    text-align: center;
  }

  .error {
    font-size: 0.75rem;
    line-height: 1.2;
    text-align: center;
    color: var(--color-brand-red);
    margin-bottom: 1.25rem;
    transition: .3s all ease-in-out;
  }

  .form {
    width: 23.125rem;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .forgotPassword {
      width: fit-content;
      margin: 0 auto 1rem;
    }
  }

  .codeInputWrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    transition: .3s all ease-in-out;
    height: 4.5rem;
    overflow: hidden;
    padding-inline: 0.5rem;
    opacity: 1;
  }

  .codeInputRow {
    display: flex !important;
    gap: 0.5rem;
  }

  .authButton {
    width: 17.5rem;
    max-width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .backLink{
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }

  .divider {
    display: flex;
    align-items: center;
    gap: 15px;
    width: 100%;

    .line {
      flex: 1 1 auto;
      height: 1px;
      background: var(--color-stroke);
    }

    .text {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1;
      color: var(--color-primary);
    }
  }

  .link {
    color: var(--color-primary);
    font-size: 0.75rem;
  }

  .verificationLoading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 0.5rem;
  }
}


@media screen and (max-width: 768px) {
  .container {
    .footer {
      flex-direction: column;
    }
  }
}

@font-family: 'DM Sans', sans-serif;@border-radius-base: 0.5rem;