.dataBox {
  overflow: auto;
  padding-right: 0.5rem;
  height: 100%;
  display: grid;
  grid-template-rows: max-content;
  grid-template-columns: 1fr max-content;
  gap: 0.5rem;
  max-height: 10rem;
}

.dataBox,
.messagesBox {
  /* Custom scrollbar */
  &::-webkit-scrollbar {
    width: 0.25rem;
  }

  &::-webkit-scrollbar-track {
    background-color: #F2FCFF;
    border-radius: 0.25rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-primary);
    border-radius: 0.25rem;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: var(--color-stroke);
  }
}

.messagesBox {
  overflow: auto;
  height: fit-content;
  padding-right: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-height: 10rem;

  .simpleMessage {
    margin: 0;
    font-size: 0.75rem;
  }

  .message {
    margin: 0;
    padding: 0.5rem;
    max-width: 80%;
    color: var(--color-secondary);
    font-size: 0.75rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1rem;
    border-radius: 0.5rem 0.5rem 0.5rem 0;
    background-color: var(--color-primary);
    align-self: flex-start;
  }

  .sent {
    border-radius: 15px 15px 0 15px;
    background-color: var(--color-brand-blue);
    align-self: flex-end;
  }
}

@font-family: 'DM Sans', sans-serif;@border-radius-base: 0.5rem;