.sidebarContainer {
  position: absolute;
  width: 4rem;
  height: 100%;
  top: 0;
  left: 0;
  padding: 2rem 0.75rem 1rem;
  transition: all .3s;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: var(--color-black);
  z-index: 1000;

  &.expanded {
    width: 15rem;
    box-shadow: 0 2px 15px 0 var(--color-black);
  }

  .menuContainer {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    transition: all .3s;
    width: 100%;
  }

  .menuList {
    list-style-type: none;
    margin: 1.5rem 0 0;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    gap: 1rem;
  }

  .menuLink {
    color: var(--color-white);
    font-size: 1rem;
    text-decoration: none;
    display: flex;
    gap: 1rem;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    white-space: nowrap;
    padding: 0.5rem;
    overflow: hidden;
    width: 100%;

    &:focus {
      color: var(--color-white);
    }

    .menuLinkText {
      color: inherit;
      margin: 0;
    }
  }

  .menuItem {
    width: 100%;
    height: 2.5rem;
    border-radius: 0.5rem;
    background: transparent;
    transition: all .3s linear;
    border: 0;
    color: var(--color-white);
    padding: 0;

    &:hover {
      .menuLink {
        color: var(--color-brand-blue);

        &:before {
          display: none;
        }
      }
    }

    &.active {
      background: var(--color-brand-blue);

      &:hover {
        background: var(--color-white);
      }
    }
  }

  .menuLinkIcon {
    display: flex;
    color: inherit;
    font-size: 1.25rem;
  }

  .logoLink {
    height: 4rem;
    display: flex;
    align-items: center;
    padding: 0;

    .logo {
      object-fit: contain;
      object-position: center center;
      font-size: 1.25rem;
      width: 2.5rem;
    }
  }

  .menuSection {
    margin-top: 1rem;
    width: calc(100% + 1.5rem);
    margin-inline: -0.75rem;
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid var(--color-stroke);
    padding: 0.75rem 0.75rem 0;

    .expandButton {
      padding: 0;
      color: var(--color-white);
      font-size: 1rem;
      border: 0;
      width: 40px;
      display: flex;
      flex-direction: column;
      rotate: 180deg;
      transition: all .3s;

      &:hover {
        color: var(--color-brand-blue);
      }

      &.expanded {
        rotate: 360deg;
      }
    }
  }
}

.substrate {
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 100vh;
  opacity: 0;
  backdrop-filter: blur(10px);
  transition: opacity .3s;
  z-index: 1000;

  &.expanded {
    width: 100vw;
    opacity: 1;
  }
}

.container {
  display: flex;
  width: calc(100vw - 4rem); // exclude sidebar width
  height: 100vh;
  position: relative;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  margin-left: 4rem; // exclude sidebar width

  .content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    gap: 1.5rem;
    padding: 2rem 1rem 1rem;
    max-width: calc(100vw - 8rem);
  }

  .header {
    flex: 0 0 4rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: space-between;

    .title {
      margin: 0;
      color: var(--color-primary);
      font-size: 2.25rem;
      font-weight: bold;
      line-height: 1.2;
    }

    .notificationsButton {
      min-width: 0;
      width: fit-content;
      height: fit-content;
      padding: 0.5rem;
      border-radius: 0.5rem;
      color: var(--color-primary);
      transition: .3s all ease-in-out;

      &:hover {
        background: var(--color-brand-blue);
        color: var(--color-white);
      }

      .notifications {
        color: inherit;
        font-size: 1.25rem;
      }
    }


    .username {
      color: var(--color-primary);
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1.1875rem;
    }

    .logout {
      color: var(--color-primary);
      font-size: 0.75rem;
      line-height: 1.1875rem;
      padding: 0;
      min-width: 0;
      font-weight: 400;
      width: fit-content;
      height: fit-content;
    }
  }

  .contentWrapper {
    overflow: hidden;
    position: relative;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
  }
}

@media screen and (min-width: 36rem) {
  .content {
    width: min(33.75rem, 100%);
  }
}

@media screen and (min-width: 48rem) {
  .content {
    width: min(45rem, 100% - 8rem);
  }
}

@media screen and (min-width: 48rem) {
  .content {
    width: min(45rem, 100% - 8rem);
  }
}

@media screen and (min-width: 62rem) {
  .content {
    width: min(60rem, 100% - 8rem);
  }
}

@media screen and (min-width: 75rem) {
  .content {
    width: min(71.25rem, 100% - 10rem);
  }
}

@media screen and (min-width: 87.5rem) {
  .content {
    width: min(82.5rem, 100% - 10rem);
  }
}

@font-family: 'DM Sans', sans-serif;@border-radius-base: 0.5rem;