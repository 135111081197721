.iconButton {

  svg {
    color: var(--color-primary);
    transition: .3s all ease-in-out;
    transform: rotate(0);
  }

  &:hover {
    svg {
      transform: rotate(90deg);
    }
  }
}

.addButton {
  &:hover {
    svg {
      color: var(--color-brand-blue);
    }
  }
}

.deleteButton {
  &:hover {
    svg {
      color: var(--color-brand-red);
    }
  }
}

.domainRow {
  background: var(--color-table-row);
  padding: 0.5rem 0 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.code {
  padding: 1rem;
  border: 1px solid var(--color-stroke);
  font-family: "Courier New", sans-serif !important;
  font-size: 0.75rem;
  letter-spacing: 0;
  line-height: 1.2;
  color: var(--color-stroke);
  white-space: pre-line;
}


@font-family: 'DM Sans', sans-serif;@border-radius-base: 0.5rem;