.card {
  border: 0 !important;
  border-radius: 0.5rem;
  background: var(--color-card-background) !important;
  transition: .3s all ease-in-out;

  .cardContent {
    position: relative;
    padding: 1rem 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;

    .title {
      color: var(--color-primary);
      font-size: 1.25rem;
      font-weight: bold;
      line-height: 1.2;
      margin: 0;
      width: 100%;
    }
  }
}

@font-family: 'DM Sans', sans-serif;@border-radius-base: 0.5rem;