.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  max-width: 400px;

  .playButton {
    transition: all .3s;
    height: fit-content;
    color: var(--color-primary);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0;

    &:hover {
      color: var(--color-brand-blue);
    }

    &:focus {
      color: var(--color-primary);
    }

    .pause {
      display: none;
    }

    .started {
      .play {
        display: none;
      }

      .pause {
        display: inline;
      }
    }
  }

  .waveContainer {
    position: relative;
    width: 100%;
    height: 0.5rem;
    background: var(--color-audio-background);
    border-radius: 0.5rem;
    overflow: hidden;

    .wave {
      width: 100%;
      height: 100%;
    }

    .waveWrapper {
      height: 100%;
    }

    .progress {
      border-radius: 0 0.5rem 0.5rem 0;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      width: 0;
      height: 100%;
      overflow: hidden;
      box-sizing: inherit;
      opacity: 0;
      background: var(--color-brand-blue);
    }
  }
}

@font-family: 'DM Sans', sans-serif;@border-radius-base: 0.5rem;