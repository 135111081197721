.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;

  &Inactive {
    .title,
    .value {
      color: var(--color-stroke) !important;
    }
  }

  .statsBox {
    padding: 0.75rem 1.5rem;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;

    .title {
      font-size: 1rem;
      white-space: nowrap;
    }

    .value {
      font-size: 1.5rem;
    }


  }
}


@font-family: 'DM Sans', sans-serif;@border-radius-base: 0.5rem;