.wrapper {
  display: flex;
  flex-direction: column;
  text-align: center;

  .message {
    color: var(--color-primary);
  }
}

@font-family: 'DM Sans', sans-serif;@border-radius-base: 0.5rem;