.modalContent {
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .modalFooter {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    align-items: center;
  }
}

@font-family: 'DM Sans', sans-serif;@border-radius-base: 0.5rem;