.loading {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%;
}

.absolute {
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.fixed {
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.loader {
  aspect-ratio: 1;
  background:
    no-repeat radial-gradient(farthest-side,var(--color-brand-blue) 94%,#0000) 0    0,
    no-repeat radial-gradient(farthest-side,var(--color-brand-green) 94%,#0000) 100% 0,
    no-repeat radial-gradient(farthest-side,var(--color-brand-orange) 94%,#0000) 100% 100%,
    no-repeat radial-gradient(farthest-side,var(--color-brand-red) 94%,#0000) 0    100%;
  background-size: 40% 40%;
  animation: l38 3s infinite;
}

.small {
  width: 15px;
}

.medium {
  width: 25px;
}

.large {
  width: 35px;
}


@keyframes l38 {
  0% {background-position: 0 0,100% 0,100% 100%,0 100%}
  25% {background-position: 100% 0,100% 100%,0 100%, 0 0}
  50% {background-position: 100% 100%,0 100%, 0 0, 100% 0}
  75% {background-position: 0 100%, 0 0, 100% 0, 100% 100%}
  100% {background-position: 0 0,100% 0,100% 100%,0 100%}
}

@font-family: 'DM Sans', sans-serif;@border-radius-base: 0.5rem;