.modalContent {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .modalBody {

  }

  .modalFooter {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    align-items: center;
  }
}

.input {
  background: var(--color-secondary) !important;
  color: var(--color-primary) !important;
}

@font-family: 'DM Sans', sans-serif;@border-radius-base: 0.5rem;