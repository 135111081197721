.layout {
  height: 100vh;
  width: 100vw;
  background: url("../../../../public/logo_blured.svg") 0 50%;
  background-size: 250%;
  animation: backgroundMove 40s linear infinite;

  display: flex;
  flex-direction: column;
  position: relative;
}


.container {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  height: 100%;
  position: relative;
  padding: 2rem 0.75rem 1rem;

}

.contentWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content {
  height: fit-content;
  padding: 2.5rem;
  background: var(--color-card-background);
  border-radius: 0.5rem;
  box-shadow: 10px 10px 100px 0 var(--color-card-background);
  display: flex;
  flex-direction: column;
  max-width: 90dvw;
  max-height: 90dvh;
  overflow: hidden;
}

.header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;

  .username {
    color: var(--color-black);
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.1875rem;
  }

  .logout {
    color: var(--color-black);
    font-size: 0.75rem;
    line-height: 1.1875rem;
    padding: 0;
    min-width: 0;
    font-weight: 400;
    width: fit-content;
    height: fit-content;
  }
}

@media screen and (max-width: 768px) {
  .content {
    padding: 2.5rem 1.5rem 1.5rem;
  }
}


@keyframes backgroundMove {
  0% {
    background-position: 0 50%;
  }

  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}


@media screen and (min-width: 36rem) {
  .container {
    width: min(33.75rem, 100%);
  }
}

@media screen and (min-width: 48rem) {
  .container {
    width: min(45rem, 100% - 8rem);
  }
}

@media screen and (min-width: 48rem) {
  .container {
    width: min(45rem, 100% - 8rem);
  }
}

@media screen and (min-width: 62rem) {
  .container {
    width: min(60rem, 100% - 8rem);
  }
}

@media screen and (min-width: 75rem) {
  .container {
    width: min(71.25rem, 100% - 10rem);
  }
}

@media screen and (min-width: 87.5rem) {
  .container {
    width: min(82.5rem, 100% - 10rem);
  }
}


@font-family: 'DM Sans', sans-serif;@border-radius-base: 0.5rem;